import image1 from "../assets/mrb.png";
import image2 from "../assets/soor.png";

export const testimonialsData = [
  {
    image: image1,
    review: 'Wsa was made so very active players could be in a clan with other super active people.I got tired of constantly being the only one on In other clans, Hence the wsa stands for we stay active. We have something for everyone.',
    name: 'Mr Bianco',
    status: 'WSA Leader'
  },
  {
    image: image2,
    review:
      "Wsa is the best clan I've been a part of so far. Friendly and helpful people but most importantly it is super well organized and the discord server is awesome. Would recommend for every active clasher.",
    name: 'SOOR',
    status : 'WSA MEMBER'
  },
];
